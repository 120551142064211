import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

/**
 * Input filters to get unique file types
 */
export const ScannedObjectFileTypesFiltersInput = mkInput({
  name: 'ScannedObjectFileTypesFiltersInput',
  comment: 'Input for filtering unique file types',
  fields: {
    parentId: {
      comment: 'The ID of the scannedObjectPath',
      type: 'id',
      modelName: 'scannedObjectPath',
      optional: true,
    },
    dataSiloId: {
      comment: 'The ID of the data silo',
      type: 'id',
      modelName: 'dataSilo',
    },
  },
});

/** Override type */
export type ScannedObjectFileTypesFiltersInput = SchemaToType<
  typeof ScannedObjectFileTypesFiltersInput
>;

/**
 * Output for getting unique file types
 */
export const ScannedObjectFileTypesOutput = mkType({
  name: 'ScannedObjectFileTypesOutput',
  comment: 'Output for getting unique file types',
  fields: {
    uniqueFileTypes: {
      comment: 'The unique file types',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type ScannedObjectFileTypesOutput = SchemaToType<
  typeof ScannedObjectFileTypesOutput
>;
