import { cases } from '@transcend-io/handlebars-utils';
import { getEntries, getStringKeys, Gql, gql } from '@transcend-io/type-utils';

import {
  isDhEncryptedParam,
  isSchema,
  isSchemaFieldTypeOptional,
  isSchemaId,
  isSchemaList,
} from './typeGuards';
import type {
  AnySchema,
  Endpoint,
  EndpointParam,
  EndpointParams,
  GraphQLParameter,
  GraphQLResponse,
  ResponseToType,
  Schema,
  SchemaField,
  SchemaFields,
  SchemaToType,
  SchemaType,
} from './types';

export const SCHEMA_FIELD_MAP: { [k in string]: string } = {
  string: 'String',
  Date: 'Date',
  float: 'Float',
  boolean: 'Boolean',
  int: 'Int',
  id: 'ID',
};

/**
 * Turn into an list
 *
 * @param isList - Whether to convert to list
 * @param t - The inner type
 * @returns The GraphQL type with list enforced if applicable
 */
const withList = (isList: boolean, t: string): string =>
  isList ? `[${t}!]` : t;

/**
 * Convert the schem field definitions intoGraphQL schema
 *
 * @param fields - Schema field definitions to convert
 * @param name - The name of the schema, for error handling
 * @returns Stringified to GraphQL typeDefinitions
 */
function makeFields<TFields extends SchemaFields>(
  fields: TFields,
  name: string,
): string {
  return getEntries(fields)
    .map(
      ([
        fieldName,
        { type, list, doubleList, optional, comment: fieldComment, deprecated },
      ]) => {
        // Determine the GraphQLType
        const nonFunc = typeof type === 'function' ? type() : type;
        const graphqlType =
          typeof nonFunc === 'object'
            ? typeof nonFunc.name === 'string'
              ? nonFunc.name
              : Object.keys(nonFunc)[0]
            : SCHEMA_FIELD_MAP[nonFunc] || nonFunc;

        // Catch recursion errors
        if (!graphqlType) {
          throw new Error(
            `Failed to get type for field: "${name}.${fieldName.toString()}"`,
          );
        }

        // Construct the GraphQL typeDef
        return `      """
        ${fieldComment}
        """
        ${fieldName.toString()}: ${withList(
          !!doubleList,
          withList(!!list, graphqlType),
        )}${optional ? '' : '!'}${deprecated ? ` @deprecated(reason: "${deprecated}")` : ''}`;
      },
    )
    .join('\n');
}

const CONVERT_SCHEMA: {
  [type in SchemaType]: <
    TSchema extends Schema<
      string,
      { [propName in string]: SchemaField },
      type,
      boolean,
      boolean
    >,
  >(
    schema: TSchema,
  ) => Gql<SchemaToType<TSchema>>;
} = {
  input: (schema) => gql`
  """
  ${schema.comment}
  """
  input ${schema.name} {
${makeFields(schema.fields, schema.name)}
  }
`,
  type: (schema) => gql`
  """
  ${schema.comment}
  """
  type ${schema.name} ${
    schema.interfaces?.length
      ? `implements ${schema.interfaces.map(({ name }) => name).join('&')} `
      : ''
  }{
${makeFields(schema.fields, schema.name)}
  }
`,
  interface: (schema) => gql`
    """
    ${schema.comment}
    """
    interface ${schema.name} {
${makeFields(schema.fields, schema.name)}
    }
  `,
};

/**
 * Convert a schema definition into a typeDef
 *
 * @param schema - The schema definition to convert
 * @returns The backend GraphQL typeDef
 */
export function mkTypeDef<TSchema extends AnySchema>(
  schema: TSchema,
): Gql<SchemaToType<TSchema>> {
  if (!schema.type) {
    throw new Error(`No type provided for: ${schema.name}`);
  }
  const mapper = CONVERT_SCHEMA[schema.type];
  return (mapper as any)(schema);
}

/**
 * Convert an endpoint parameters
 *
 * @param definition - The parameter definition
 * @returns The GraphQL typeDef name
 */
export function paramToGraphQLType(definition: EndpointParam): string {
  const isOptional = typeof definition !== 'string' && definition.isOptional;

  // If the definition is a string, its either a native type or a schema type
  if (typeof definition === 'string') {
    return `${SCHEMA_FIELD_MAP[definition] || definition}!`;
  }

  if (isSchemaFieldTypeOptional(definition)) {
    return `${SCHEMA_FIELD_MAP[definition.type]}${isOptional ? '' : '!'}${
      definition.default === undefined ? '' : ` = ${definition.default}`
    }`;
  }

  if (isDhEncryptedParam(definition)) {
    return `String${isOptional ? '' : '!'}`;
  }

  return `${withList(isSchemaList(definition), definition.name || 'ID')}${
    isOptional ? '' : '!'
  }`;
}

/**
 * Map an endpoint definition to the GraphQL schema language
 *
 * @param endpoint - The endpoint definition
 * @returns The gql fragment for the endpoint definition
 */
export function endpointToGraphQLSchema<
  TName extends string,
  TParams extends EndpointParams,
  TResult extends GraphQLResponse | AnySchema,
>({
  comment,
  params,
  name,
  response,
}: Endpoint<TName, TParams, TResult>): {
  /** The GraphQL route typeDef definition */
  route: Gql<{ [k in TName]: ResponseToType<TResult> }>;
  /** The typeDef used for the response type of the route definition */
  responseTypeDef: Gql<
    ResponseToType<TResult> & {
      /** All requests forward a clientMutationId */
      clientMutationId?: string;
    }
  > | null;
} {
  // Construct the input parameters
  const inputs = getEntries(params)
    .map(
      ([paramName, definition]) =>
        `${paramName.toString()}: ${paramToGraphQLType(definition as any)}`,
    )
    .join(', ');

  // Wrap input in parentheses
  const wrappedInput = inputs.length > 0 ? `(${inputs})` : '';

  // Determine whether to create a dynamic response
  const responseName = isSchema(response)
    ? (response.name as string)
    : `${cases.pascalCase(name)}Payload`;

  // No need to generate a response typeDef
  if (isSchema(response)) {
    return {
      route: gql`
      """
      ${comment}
      """
      ${name}${wrappedInput}: ${response.isList ? '[' : ''}${responseName}${
        response.isList ? '!]' : ''
      }${response.isOptional ? '' : '!'}
     ` as Gql<{ [k in TName]: ResponseToType<TResult> }>,
      responseTypeDef: null,
    };
  }

  // Construct the response type fields
  const responseQueries = getStringKeys(response)
    .map((k) => {
      const value = response[k] as GraphQLParameter;
      return gql`
    """
    ${
      typeof value === 'object'
        ? isSchemaId(value)
          ? value.comment || `ID for model ${value.modelName}`
          : isSchemaList(value)
            ? `A list of ${value.name}`
            : (value as any).comment
        : value
    }
    """
    ${k}: ${
      typeof value === 'string'
        ? SCHEMA_FIELD_MAP[value]
        : isSchemaId(value)
          ? value.name || 'ID'
          : withList(isSchemaList(value), value.name)
    }${typeof value === 'object' && value.isOptional ? '' : '!'}
    `;
    })
    .join('\n');

  return {
    route: gql`
    """
    ${comment}
    """
    ${name}${wrappedInput}: ${responseName}!
   ` as Gql<{ [k in TName]: ResponseToType<TResult> }>,
    /**
     * A typeDef that represents the return type of the mutation
     */
    responseTypeDef: gql`type ${responseName} {
    """
    The optional clientMutationId in the input type is returned if it was provided
    """
    clientMutationId: String
    ${responseQueries}
  }` as Gql<
      ResponseToType<TResult> & {
        /** All requests forward a clientMutationId */
        clientMutationId?: string;
      }
    >,
  };
}
