/* eslint-disable max-lines */
import {
  DataCategoryType,
  RequestAction,
  RequestActionObjectResolver,
} from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import { AttributeInput, AttributeValue } from '@main/attribute-types';
import { DefinedMessage } from '@main/internationalization';
import {
  mkInput,
  mkInterface,
  mkOrder,
  mkType,
  SchemaToType,
} from '@main/schema-utils';
import { DecryptionContext, TableEncryptionType } from '@main/sombra-types';

import { DataPointMetadata } from './catalog';
import { DataCollection } from './dataCollection';
import { DataSiloPreview, RequestFileDataSiloPreview } from './dataSiloPreview';
import { DataInventoryCategory, DataSubCategoryInput } from './dataSubCategory';
import {
  ContentClassificationDataPointOrderField,
  DataPointExportMode,
  DataPointOrderField,
  ErasureRedactionMethod,
} from './enums';
import { PurposeSubCategoryInput } from './processingPurpose';

export const DbIntegrationQuerySuggestionInput = mkInput({
  name: 'DbIntegrationQuerySuggestionInput',
  comment: 'Suggestion for a new SQL query to run against DSRs',
  fields: {
    suggestedQuery: {
      comment: 'The suggested database query if different than the original',
      type: 'string',
    },
    requestType: {
      comment: 'The request type',
      type: { RequestActionObjectResolver },
    },
  },
});

/** Override type */
export type DbIntegrationQuerySuggestionInput = SchemaToType<
  typeof DbIntegrationQuerySuggestionInput
>;

export const DataPointSubDataPointInput = mkInput({
  name: 'DataPointSubDataPointInput',
  comment:
    'Input for updating/creating a subDatapoint as part of updating/creating a datapoint',
  fields: {
    // subdatapoints for one datapoint are unique by name
    name: {
      comment: 'The name of the subdatapoint',
      type: 'string',
    },
    description: {
      comment: 'A description for the subdatapoint',
      type: 'string',
      optional: true,
    },
    accessRequestVisibilityEnabled: {
      comment: `When true, this subdatapoint should be revealed in a data access request.
When false, this field should be redacted`,
      type: 'boolean',
      optional: true,
    },
    erasureRequestRedactionEnabled: {
      comment: `When true, this subdatapoint should be redacted during an erasure request.
There normally is a choice of enabling hard deletion or redaction at the
datapoint level, but if redaction is enabled, this column can be used
to define which fields should be redacted.`,
      type: 'boolean',
      optional: true,
    },
    categories: {
      comment: 'The category of personal data for this subdatapoint',
      type: DataSubCategoryInput,
      list: true,
      optional: true,
    },
    purposes: {
      comment: 'The processing purposes for this subdatapoint',
      type: PurposeSubCategoryInput,
      list: true,
      optional: true,
    },
    attributes: {
      comment: 'The attribute values used to label this subdatapoint',
      type: AttributeInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type DataPointSubDataPointInput = SchemaToType<
  typeof DataPointSubDataPointInput
>;

export const UpdateOrCreateDataPointInput = mkInput({
  name: 'UpdateOrCreateDataPointInput',
  comment: 'Input for updating a datapoint',
  fields: {
    dataSiloId: {
      comment: 'The id of the data silo to create the datapoint for',
      type: 'id',
      modelName: 'dataSilo',
    },
    id: {
      comment: 'The id of the datapoint to update',
      type: 'id',
      optional: true,
      modelName: 'dataPoint',
    },
    title: {
      comment: 'The title of the datapoint',
      optional: true,
      type: 'string',
    },
    enabledActions: {
      comment: 'The actions that the datapoint should connect to',
      type: { RequestActionObjectResolver },
      optional: true,
      list: true,
    },
    dataCollectionId: {
      comment: 'The id of the related data collection',
      type: 'id',
      modelName: 'dataCollection',
      optional: true,
    },
    dataCollectionTag: {
      comment:
        'The title of the data collection to assign to the datapoint.' +
        'If the collection does not exist, one will be created.',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'A description for the datapoint',
      optional: true,
      type: 'string',
    },
    erasureRedactionMethod: {
      comment: 'Method for redacting data in an erasure request',
      optional: true,
      type: { ErasureRedactionMethod },
    },
    path: {
      comment:
        'Usually only relevant for database silos, ' +
        'where datapoints can be uniquely identified by listing out the schemas that they belong to. ' +
        'The list of schema names MUST appear in order e.g., ' +
        '["ANALYTICS","public"] is different from ["public","ANALYTICS"]',
      type: 'string',
      optional: true,
      list: true,
    },
    name: {
      comment:
        'The datapoint name (used to key by). This is deprecated. Please use "fullyQualifiedName" instead.',
      optional: true,
      type: 'string',
    },
    querySuggestions: {
      comment: 'The suggested SQL queries to run for a DSR',
      type: DbIntegrationQuerySuggestionInput,
      optional: true,
      list: true,
    },
    upsertSubDataPointsOnly: {
      comment: 'When true, upsert subdatapoints instead of adding & deleting',
      optional: true,
      type: 'boolean',
    },
    subDataPoints: {
      comment: 'The subdatapoints associated with this datapoint',
      type: DataPointSubDataPointInput,
      optional: true,
      list: true,
    },
    attributes: {
      comment:
        'user defined key-value pairs that can be used to label datapoints',
      optional: true,
      list: true,
      type: AttributeInput,
    },
    exportMode: {
      comment: 'The export mode of the datapoint',
      optional: true,
      type: { DataPointExportMode },
    },
    ownerIds: {
      comment:
        'The unique ids of the users to assign as owners of this datapoint',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The unique ids of the teams to assign as owners of this datapoint',
      modelName: 'team',
      type: 'id',
      optional: true,
      list: true,
    },
    ownerEmails: {
      comment: 'The emails of the users to assign as owners of this datapoint.',
      type: 'string',
      optional: true,
      list: true,
    },
    teamNames: {
      comment:
        'The names of the teams that should be responsible for this datapoint',
      type: 'string',
      list: true,
      optional: true,
    },
    scannedDescription: {
      comment: 'The description of a datapoint',
      type: 'string',
      optional: true,
    },
    descriptionWasManuallyUpdated: {
      comment: 'Whether description has been manually updated by user',
      type: 'boolean',
      optional: true,
    },
    encryption: {
      comment: 'The encryption type for the datapoint',
      type: { TableEncryptionType },
      optional: true,
    },
  },
});

/** Override type */
export type UpdateOrCreateDataPointInput = SchemaToType<
  typeof UpdateOrCreateDataPointInput
>;

export const DeleteDataPointsInput = mkInput({
  name: 'DeleteDataPointsInput',
  comment: 'Input for deleting a group of datapoints',
  fields: {
    ids: {
      comment: 'The id of the datapoint to update',
      type: 'id',
      modelName: 'dataPoint',
      list: true,
    },
  },
});

/** Override type */
export type DeleteDataPointsInput = SchemaToType<typeof DeleteDataPointsInput>;

export const ToggleDataPointActionsInput = mkInput({
  name: 'ToggleDataPointActionsInput',
  comment: 'Input for toggling the active status of a (set of) datapoints',
  fields: {
    id: {
      comment:
        'The id of the datapoint to update, or none if all non-global datapoints',
      type: 'id',
      modelName: 'dataPoint',
      optional: true,
    },
    dataSiloId: {
      comment: 'The id of the data silo',
      type: 'id',
      modelName: 'dataSilo',
    },
    action: {
      comment: 'The action to toggle on or off',
      type: { RequestActionObjectResolver },
    },
    active: {
      comment: 'Whether to turn on the datapoint action',
      type: 'boolean',
    },
  },
});

/** Override type */
export type ToggleDataPointActionsInput = SchemaToType<
  typeof ToggleDataPointActionsInput
>;

export const DataPointInterface = mkInterface({
  name: 'DataPointInterface',
  comment: 'A datapoint is a granular piece of data with a data silo',
  fields: {
    id: {
      comment: 'The unique ID of the datapoint',
      modelName: 'dataPoint',
      type: 'id',
    },
    title: {
      comment: 'The display title of the datapoint',
      type: DefinedMessage,
      optional: true,
    },
    description: {
      comment: 'A brief overview of the datapoint',
      type: DefinedMessage,
      optional: true,
    },
    name: {
      comment: 'The identifying name/key of the datapoint',
      type: 'string',
    },
    metadata: {
      comment: 'Additional metadata about the datapoint',
      type: DataPointMetadata,
    },
    slug: {
      comment: 'The slug of the datapoint for templating',
      type: 'string',
    },
    // this is redundant with dataSilo.id but useful
    // as the subDataPoints query is often called at high volumes
    // and optimizing every last db call is useful
    dataSiloId: {
      comment: 'The id of the data silo that contains the datapoint',
      type: 'id',
      modelName: 'dataSilo',
    },
    encryption: {
      comment: 'The encryption type for the datapoint',
      optional: true,
      type: { TableEncryptionType },
    },
  },
});

/** Override type */
export type DataPointInterface = SchemaToType<typeof DataPointInterface>;

export const DataPointPreview = mkType({
  name: 'DataPointPreview',
  comment:
    'Previews associated datapoint information. Used as download file metadata',
  interfaces: [DataPointInterface],
  fields: {
    ...DataPointInterface.fields,
  },
});

/** Override type */
export type DataPointPreview = SchemaToType<typeof DataPointPreview>;

export const DataPointPreviewWithDataSilo = mkType({
  name: 'DataPointPreviewWithDataSilo',
  comment:
    'Previews associated datapoint information with associated data silo',
  interfaces: [DataPointInterface],
  fields: {
    ...DataPointInterface.fields,
    dataSilo: {
      comment: 'The data silo for this datapoint',
      type: DataSiloPreview,
    },
  },
});

/** Override type */
export type DataPointPreviewWithDataSilo = SchemaToType<
  typeof DataPointPreviewWithDataSilo
>;

export const RequestFileDataPointPreview = mkType({
  name: 'RequestFileDataPointPreview',
  comment:
    'Previews associated datapoint information. Used as download file metadata',
  interfaces: [DataPointInterface],
  fields: {
    ...DataPointInterface.fields,
    dataSilo: {
      comment: 'The data silo for this datapoint',
      type: () => RequestFileDataSiloPreview,
    },
    path: {
      comment: 'The hierarchy of levels associated with this datapoint',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type RequestFileDataPointPreview = SchemaToType<
  typeof RequestFileDataPointPreview
>;

export const DataPointActionSetting = mkType({
  name: 'DataPointActionSetting',
  comment: 'A setting for a datapoint/action pair',
  fields: {
    type: {
      comment: 'The type of action',
      type: { RequestActionObjectResolver },
    },
    active: {
      comment: 'True if that datapoint is currently on for the action',
      type: 'boolean',
    },
    allowed: {
      comment:
        'True if that datapoint is allowed to be configured for this data silo',
      type: 'boolean',
    },
  },
});

/** Override type */
export type DataPointActionSetting = SchemaToType<
  typeof DataPointActionSetting
>;

export const DbIntegrationQuery = mkType({
  name: 'DbIntegrationQuery',
  comment:
    'The query to pass to a database integration to resolve a specific DSR',
  fields: {
    query: {
      comment: 'The database query to execute',
      type: 'string',
      optional: true,
    },
    suggestedQuery: {
      comment: 'The suggested database query if different than the original',
      type: 'string',
      optional: true,
    },
    requestType: {
      comment: 'The request type',
      type: { RequestActionObjectResolver },
    },
    id: {
      comment: 'Id of the query',
      type: 'id',
      modelName: 'databaseIntegrationQuery',
    },
  },
});

/** Override type */
export type DbIntegrationQuery = SchemaToType<typeof DbIntegrationQuery>;

export const GuessedPersonalDataCount = mkType({
  name: 'GuessedPersonalDataCount',
  comment:
    'The number of guessed personal data subDataPoints beneath a DataPointHierarchyItem',
  fields: {
    highConfidence: {
      comment: 'The number of top guesses with high confidence',
      type: 'int',
    },
    mediumConfidence: {
      comment: 'The number of top guesses with medium confidence',
      type: 'int',
    },
    lowConfidence: {
      comment: 'The number of top guesses with low confidence',
      type: 'int',
    },
  },
});

/** Override type */
export type GuessedPersonalDataCount = SchemaToType<
  typeof GuessedPersonalDataCount
>;

export const WorkflowDataPointIdentifier = mkType({
  name: 'WorkflowDataPointIdentifier',
  comment: 'A workflow identifier for a datapoint',
  fields: {
    identifier: {
      type: 'string',
      comment: 'The identifier of the datapoint',
    },
    selected: {
      type: 'boolean',
      comment: 'Whether the identifier is selected',
    },
  },
});

/** Override type */
export type WorkflowDataPointIdentifier = SchemaToType<
  typeof WorkflowDataPointIdentifier
>;

export const WorkflowDataPoint = mkType({
  name: 'WorkflowDataPoint',
  comment: 'A workflow data point',
  fields: {
    exportMode: {
      comment: 'The export mode of the datapoint',
      optional: true,
      type: { DataPointExportMode },
    },
    order: {
      comment: 'The order of the datapoint',
      optional: true,
      type: 'int',
    },
    action: {
      comment: 'The action to toggle on or off',
      optional: true,
      type: { RequestActionObjectResolver },
    },
    selectedIdentifiers: {
      comment: 'The workflow identifiers used in this data point',
      type: WorkflowDataPointIdentifier,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type WorkflowDataPoint = SchemaToType<typeof WorkflowDataPoint>;

export const DataPoint = mkType({
  name: 'DataPoint',
  comment:
    'A sub-categorization of data with a data silo, from internal perspective',
  interfaces: [DataPointInterface],
  fields: {
    ...DataPointInterface.fields,
    dataSubCategories: {
      comment:
        'The set of data categories assigned to the sub-datapoints of this datapoint',
      type: DataInventoryCategory,
      list: true,
    },
    canDelete: {
      comment:
        'Whether or not the datapoint can be deleted. ' +
        'Datapoints that are managed by Transcend cannot be deleted.',
      type: 'boolean',
    },
    actionSettings: {
      comment: 'Settings for datapoint actions',
      list: true,
      type: DataPointActionSetting,
    },
    erasureRedactionMethod: {
      comment: 'Method for redacting data in an erasure request',
      optional: true,
      type: { ErasureRedactionMethod },
    },
    dbIntegrationQueries: {
      comment:
        'The query to pass to a database integration to resolve a specific DSR',
      optional: true,
      list: true,
      type: DbIntegrationQuery,
    },
    dataCollection: {
      comment: 'The data collection that the datapoint is associated with',
      optional: true,
      type: DataCollection,
    },
    dataSilo: {
      comment: 'The data silo for this datapoint',
      type: DataSiloPreview,
    },
    path: {
      comment: 'The hierarchy of levels associated with this datapoint',
      type: 'string',
      list: true,
    },
    subDataPointCount: {
      comment: 'The number of subdatapoints this datapoint has beneath it',
      type: 'int',
    },
    accessRequestVisibilityCount: {
      comment:
        'The number of subdatapoints set to be returned in an access request',
      type: 'int',
    },
    erasureRequestRedactionCount: {
      comment:
        'The number of subdatapoints set to be redacted in an erasure request',
      type: 'int',
    },
    confirmedPersonalDataCount: {
      comment:
        'The number of confirmed personal data subdatapoints beneath this datapoint',
      type: 'int',
    },
    guessedPersonalDataCount: {
      comment:
        'The number of guessed personal data subdatapoints beneath this datapoint',
      type: GuessedPersonalDataCount,
    },
    exportMode: {
      comment: 'The export mode of the datapoint',
      type: { DataPointExportMode },
    },
    owners: {
      comment:
        'The list of individual users who are responsible for managing this datapoint',
      list: true,
      type: UserPreview,
    },
    teams: {
      comment:
        'The list of teams who are responsible for managing this datapoint',
      list: true,
      type: TeamPreview,
    },
    scannedDescription: {
      comment: 'The description that comes from a plugin result',
      type: 'string',
      optional: true,
    },
    descriptionWasManuallyUpdated: {
      comment: 'Whether description has been manually updated by user',
      type: 'boolean',
      optional: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this assessment',
      type: AttributeValue,
      list: true,
    },
    workflowDataPoint: {
      comment: 'The workflow config for this data point',
      type: WorkflowDataPoint,
      optional: true,
    },
  },
});

/** Override type */
export type DataPoint = SchemaToType<typeof DataPoint>;

export const DataPointFiltersInput = mkInput({
  name: 'DataPointFiltersInput',
  comment: 'Input for filtering a list of datapoints',
  fields: {
    text: {
      comment: 'The text to filter the datapoints by',
      optional: true,
      type: 'string',
    },
    dataSilos: {
      comment: 'Filter by data silos the datapoints belong to',
      type: 'id',
      modelName: 'dataSilo',
      list: true,
      optional: true,
    },
    ids: {
      comment: 'Filter by datapoint IDs',
      type: 'id',
      modelName: 'dataPoint',
      list: true,
      optional: true,
    },
    owners: {
      comment: 'Filter by the assigned owner IDs',
      optional: true,
      type: 'id',
      modelName: 'user',
      list: true,
    },
    showApprovedSqlQueries: {
      comment: 'Show datapoints with approved SQL statements',
      optional: true,
      type: 'boolean',
    },
    showSuggestedSqlQueries: {
      comment: 'Show datapoints with suggested SQL statements',
      optional: true,
      type: 'boolean',
    },
    teamIds: {
      comment: 'Filter by the assigned team IDs',
      optional: true,
      type: 'id',
      modelName: 'team',
      list: true,
    },
    enabledActions: {
      comment: 'Filter by enabled actions',
      optional: true,
      list: true,
      type: { RequestAction },
    },
    categories: {
      comment:
        "Filter by category of personal data assigned to the datapoints' sub-datapoints",
      type: { DataCategoryType },
      list: true,
      optional: true,
    },
    subCategoryIds: {
      comment:
        "Filter by the IDs of confirmed data subcategories assigned to the datapoints' sub-datapoints",
      optional: true,
      list: true,
      type: 'id',
      modelName: 'dataSubCategory',
    },
    dataCollectionIds: {
      comment:
        'Filter by the IDs of data collections to which the datapoints belong',
      type: 'id',
      modelName: 'dataCollection',
      optional: true,
      list: true,
    },
    missingRequiredFields: {
      comment: 'Filter to datapoints that are missing required fields',
      type: 'boolean',
      optional: true,
    },
    attributeValueIds: {
      comment: 'The ids of the attribute values to update',
      modelName: 'attributeValue',
      type: 'id',
      list: true,
      optional: true,
    },
    dataPointLevelIds: {
      comment: 'Filter by datapoint level IDs',
      type: 'id',
      modelName: 'dataPointLevel',
      list: true,
      optional: true,
    },
    workflowId: {
      comment: 'Filter by datapoints included in a given workflow',
      type: 'id',
      modelName: 'workflowConfig',
      optional: true,
    },
    workflowGroup: {
      comment: 'Filter by workflow group. Must also specify workflowId',
      type: 'int',
      optional: true,
    },
    dataPointLevelName: {
      comment: 'Filter data points by data point level name',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type DataPointFiltersInput = SchemaToType<typeof DataPointFiltersInput>;

export const DataPointHierarchyFiltersInput = mkInput({
  name: 'DataPointHierarchyFiltersInput',
  comment: 'Input for filtering a hierarchy of datapoints',
  fields: {
    text: {
      comment: 'The text to filter the datapoints by',
      optional: true,
      type: 'string',
    },
    dataPointLevelIds: {
      comment: 'Filter by datapoint level IDs',
      type: 'id',
      modelName: 'dataPointLevel',
      list: true,
      optional: true,
    },
    dataSiloId: {
      comment: 'Filter by data silos the datapoints belong to',
      type: 'id',
      modelName: 'dataSilo',
    },
    parentLevelId: {
      comment: 'Filter by parent datapoint level ID',
      type: 'id',
      modelName: 'dataPointLevel',
      optional: true,
    },
    ownerIds: {
      comment: 'Filter by the assigned owner IDs',
      optional: true,
      type: 'id',
      modelName: 'user',
      list: true,
    },
    teamIds: {
      comment: 'Filter by the assigned team IDs',
      optional: true,
      type: 'id',
      modelName: 'team',
      list: true,
    },
    attributeValueIds: {
      comment:
        'Filter by the attribute values used to label the data hierarchy item',
      type: 'id',
      modelName: 'attributeValue',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type DataPointHierarchyFiltersInput = SchemaToType<
  typeof DataPointHierarchyFiltersInput
>;

export const ContentClassificationDataPointsFiltersInput = mkInput({
  name: 'ContentClassificationDataPointsFiltersInput',
  comment:
    'Input for filtering dataPoints for a data silo that has the classification plugin enabled',
  fields: {
    dataSiloId: {
      comment: 'Filter by data silos the datapoints belong to',
      type: 'id',
      modelName: 'dataSilo',
    },
  },
});

/** Override type */
export type ContentClassificationDataPointsFiltersInput = SchemaToType<
  typeof ContentClassificationDataPointsFiltersInput
>;

export const DataPointHierarchyPathFiltersInput = mkInput({
  name: 'DataPointHierarchyPathFiltersInput',
  comment: 'Input for filtering a hierarchy of datapoints',
  fields: {
    dataSiloId: DataPointHierarchyFiltersInput.fields.dataSiloId,
    parentLevelId: DataPointHierarchyFiltersInput.fields.parentLevelId,
    dataPointId: {
      comment: 'Add the current datapoint',
      type: 'id',
      modelName: 'dataPoint',
      optional: true,
    },
  },
});

/** Override type */
export type DataPointHierarchyPathFiltersInput = SchemaToType<
  typeof DataPointHierarchyPathFiltersInput
>;

export const DataPointHierarchyItemMetadata = mkType({
  name: 'DataPointHierarchyItemMetadata',
  comment: 'Metadata for a hierarchy item',
  fields: {
    rowCount: {
      comment: 'The number of rows in the object',
      type: 'int',
    },
  },
});

/** Override type */
export type DataPointHierarchyItemMetadata = SchemaToType<
  typeof DataPointHierarchyItemMetadata
>;

export const DataPointHierarchyItem = mkType({
  name: 'DataPointHierarchyItem',
  comment:
    'A sub-categorization of data with a data silo, from internal perspective',
  fields: {
    id: {
      comment: 'The unique ID of the datapoint or the datapoint level',
      type: 'string',
    },
    name: DataPointInterface.fields.name,
    metadata: {
      comment: 'The metadata for the object',
      type: DataPointHierarchyItemMetadata,
      optional: true,
    },
    path: {
      comment: 'The path to this hierarchy item',
      type: 'string',
      list: true,
      optional: true,
    },
    isDataPoint: {
      comment: 'Is this item a datapoint or a folder?',
      type: 'boolean',
    },
    subDataPointsCount: {
      comment: 'The total number subdatapoints beneath this hierarchy item',
      type: 'int',
    },
    confirmedSubDataPointsCount: {
      comment:
        'The number subdatapoints beneath this hierarchy item that have at least one category assigned',
      type: 'int',
    },
    confirmedPersonalDataCount: {
      comment:
        'The number of confirmed personal data subdatapoints beneath this hierarchy item',
      type: 'int',
    },
    guessedPersonalDataCount: {
      comment:
        'The number of guessed personal data subdatapoints beneath this hierarchy item',
      type: GuessedPersonalDataCount,
    },
    owners: {
      comment:
        'The list of individual users who are responsible for managing this datapoint level',
      list: true,
      type: UserPreview,
    },
    teams: {
      comment:
        'The list of teams who are responsible for managing this processing purpose sub category',
      list: true,
      type: TeamPreview,
    },
    dataSiloId: {
      comment: 'The ID of the data silo that contains the item',
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
    },
    description: {
      comment: 'The description of the data point',
      type: 'string',
      optional: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this dataPointHierarchyItem',
      type: AttributeValue,
      list: true,
    },
    encryption: {
      comment: 'The encryption type for the datapoint',
      type: { TableEncryptionType },
      optional: true,
    },
  },
});

/** Override type */
export type DataPointHierarchyItem = SchemaToType<
  typeof DataPointHierarchyItem
>;

export const UpdateDataPointLevelInput = mkInput({
  name: 'UpdateDataPointLevelInput',
  comment: 'Input for updating a dataPointLevel',
  fields: {
    id: {
      comment: 'The unique ID of datapoint level to update',
      type: 'id',
      modelName: 'dataPointLevel',
    },
    ownerIds: {
      comment:
        'The unique ids of the users to assign as owners of this datapoint level',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The unique ids of the teams to assign as owners of this datapoint level',
      modelName: 'team',
      type: 'id',
      optional: true,
      list: true,
    },
    description: {
      comment: 'The description of the datapoint',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateDataPointLevelInput = SchemaToType<
  typeof UpdateDataPointLevelInput
>;

export const UnwrapSubDataPointSamplesFiltersInput = mkInput({
  name: 'UnwrapSubDataPointSamplesFiltersInput',
  comment: 'Input for unwrapping a set of sub datapoints',
  fields: {
    subDataPointIds: {
      comment: 'The list of sub datapoints to fetch',
      type: 'id',
      modelName: 'subDataPoint',
      list: true,
    },
  },
});

/** Override type */
export type UnwrapSubDataPointSamplesFiltersInput = SchemaToType<
  typeof UnwrapSubDataPointSamplesFiltersInput
>;

export const SubDataPointSampleDecryptionContext = mkType({
  name: 'SubDataPointSampleDecryptionContext',
  comment: 'Response after unwrapping a set of sub datapoint samples',
  fields: {
    id: {
      comment: 'The id of the sub datapoint',
      type: 'id',
      modelName: 'subDataPoint',
    },
    decryptionContext: {
      comment: 'The context needed to decrypt the sample in the browser',
      type: DecryptionContext,
      optional: true,
    },
  },
});

/** Override type */
export type SubDataPointSampleDecryptionContext = SchemaToType<
  typeof SubDataPointSampleDecryptionContext
>;

/**
 * Order for a dataPoints query
 */
export const DataPointOrder = mkOrder(DataPoint.name, DataPointOrderField);

/** Override type */
export type DataPointOrder = SchemaToType<typeof DataPointOrder>;

/** Order for a content classification dataPoints query */
export const ContentClassificationDataPointsOrder = mkOrder(
  'ContentClassificationDataPoint',
  ContentClassificationDataPointOrderField,
);

/** Override type */
export type ContentClassificationDataPointsOrder = SchemaToType<
  typeof ContentClassificationDataPointsOrder
>;

export const UpdateOrCreateWorkflowDataPointInput = mkInput({
  name: 'UpdateOrCreateWorkflowDataPointInput',
  comment: 'Input for updating or creating a workflow data point',
  fields: {
    dataPointId: {
      comment: 'The id of the data point to update',
      type: 'id',
      modelName: 'dataPoint',
    },
    workflowId: {
      comment: 'The id of the workflow to update',
      type: 'id',
      modelName: 'workflowConfig',
    },
    exportMode: {
      comment: 'The export mode of the datapoint',
      optional: true,
      type: { DataPointExportMode },
    },
    order: {
      comment: 'The order of the datapoint',
      optional: true,
      type: 'int',
    },
    action: {
      comment: 'The action to toggle on or off',
      optional: true,
      type: { RequestActionObjectResolver },
    },
    selectedIdentifiers: {
      comment: 'The workflow identifiers used in this data point',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateOrCreateWorkflowDataPointInput = SchemaToType<
  typeof UpdateOrCreateWorkflowDataPointInput
>;

/* eslint-enable max-lines */
