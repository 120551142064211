import { AttributeValue } from '@main/attribute-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { DataSubCategoryPreview } from './dataSubCategory';

export const ScannedObjectHierarchyPathFiltersInput = mkInput({
  name: 'ScannedObjectHierarchyPathFiltersInput',
  comment: 'Input for filtering a hierarchy of scanned objects',
  fields: {
    dataSiloId: {
      comment: 'Filter by data silos the scanned objects belong to',
      type: 'id',
      modelName: 'dataSilo',
    },
    parentId: {
      comment: 'Filter by parent scanned object level ID',
      type: 'id',
      modelName: 'scannedObjectPath',
      optional: true,
    },
    scannedObjectId: {
      comment: 'Filter by the current scanned object',
      type: 'id',
      modelName: 'scannedObject',
      optional: true,
    },
  },
});

/** Override type */
export type ScannedObjectHierarchyPathFiltersInput = SchemaToType<
  typeof ScannedObjectHierarchyPathFiltersInput
>;

export const ScannedObjectHierarchyItemMetadata = mkType({
  name: 'ScannedObjectHierarchyItemMetadata',
  comment: 'Metadata for a hierarchy item',
  fields: {
    rowCount: {
      comment: 'The number of rows in the object',
      type: 'int',
    },
  },
});

/** Override type */
export type ScannedObjectHierarchyItemMetadata = SchemaToType<
  typeof ScannedObjectHierarchyItemMetadata
>;

export const ScannedObjectHierarchyItem = mkType({
  name: 'ScannedObjectHierarchyItem',
  comment:
    'A hierarchy item in the scanned object hierarchy, which can be a scanned object or a scanned object path',
  fields: {
    id: {
      comment: 'The unique ID of the scanned object or the scanned object path',
      type: 'string',
    },
    name: {
      comment: 'The identifying name/key of the scanned object',
      type: 'string',
    },
    metadata: {
      comment: 'The metadata for the object',
      type: ScannedObjectHierarchyItemMetadata,
      optional: true,
    },
    path: {
      comment: 'The path to this hierarchy item',
      type: 'string',
      list: true,
      optional: true,
    },
    isScannedObject: {
      comment:
        'This hierarchy item is a scanned object, rather than a scanned object path',
      type: 'boolean',
    },
    dataSiloId: {
      comment: 'The ID of the data silo that contains the item',
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
    },
    description: {
      comment:
        'The description of the scanned object or the scanned object path',
      type: 'string',
      optional: true,
    },
    owners: {
      comment: 'The owners of the scanned object or the scanned object path',
      type: 'id',
      modelName: 'user',
      list: true,
      optional: true,
    },
    teams: {
      comment: 'The teams of the scanned object or the scanned object path',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    attributeValues: {
      comment:
        'The attribute values used to label this scanned object or the scanned object path',
      type: AttributeValue,
      list: true,
    },
    fileType: {
      comment: 'The fileType for scannedObjects or null for scannedObjectPaths',
      type: 'string',
      optional: true,
    },
    size: {
      comment:
        'The size for a scannedObject or the scannedObjects within a scannedObjectPath',
      type: 'float',
    },
    guessedDataSubCategories: {
      comment:
        'The guessed data subcategories for unstructured subdatapoint recommendations belonging to scannedObjects,' +
        ' or null for scannedObjectPaths',
      type: DataSubCategoryPreview,
      list: true,
    },
    confirmedDataSubCategories: {
      comment:
        'The confirmed data subcategories for unstructured subdatapoint recommendations belonging to scannedObjects,' +
        ' or null for scannedObjectPaths',
      type: DataSubCategoryPreview,
      list: true,
    },
  },
});

/** Override type */
export type ScannedObjectHierarchyItem = SchemaToType<
  typeof ScannedObjectHierarchyItem
>;

export const ScannedObjectHierarchyFiltersInput = mkInput({
  name: 'ScannedObjectHierarchyFiltersInput',
  comment: 'Input for filtering a hierarchy of scanned objects',
  fields: {
    text: {
      comment: 'The text to filter the scanned objects by',
      optional: true,
      type: 'string',
    },
    scannedObjectPathIds: {
      comment: 'Filter by scanned object path IDs',
      type: 'id',
      modelName: 'scannedObjectPath',
      list: true,
      optional: true,
    },
    dataSiloId: {
      comment: 'Filter by data silos the scanned objects belong to',
      type: 'id',
      modelName: 'dataSilo',
    },
    parentId: {
      comment: 'Filter by parent scanned object path ID',
      type: 'id',
      modelName: 'scannedObjectPath',
      optional: true,
    },
    ownerIds: {
      comment: 'Filter by the assigned owner IDs',
      optional: true,
      type: 'id',
      modelName: 'user',
      list: true,
    },
    teamIds: {
      comment: 'Filter by the assigned team IDs',
      optional: true,
      type: 'id',
      modelName: 'team',
      list: true,
    },
    attributeValueIds: {
      comment:
        'Filter by the attribute values used to label the scanned object or scanned object path',
      type: 'id',
      modelName: 'attributeValue',
      optional: true,
      list: true,
    },
    fileTypes: {
      comment: 'Filter by the fileType for scannedObjects',
      type: 'string',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type ScannedObjectHierarchyFiltersInput = SchemaToType<
  typeof ScannedObjectHierarchyFiltersInput
>;
